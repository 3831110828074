import Header1 from "../components/Header";
import Footer from "../components/Footer";
import branch1_home from "../../../resources/images/branch1-home.png";
import br1_img from "../../../resources/images/br1-img.png";
import admission from "../../../resources/images/admission.svg";
import campus from "../../../resources/images/campus.svg";
import location from "../../../resources/images/location.svg";
import facility from "../../../resources/images/facility.svg";
import gallery from "../../../resources/images/gallery.svg";
import transport from "../../../resources/images/transport.svg";
import top1 from "../../../resources/images/top1.png";
import top2 from "../../../resources/images/top2.png";
import top3 from "../../../resources/images/top3.png";
import top4 from "../../../resources/images/top4.png";
import image12 from "../../../resources/home/new img director.jpeg";

const Home = () => {
  window.scroll(0, 0);
  return (
    <div>
      <Header1 />
      <div className="home1-container">
        <div className="home-school-img">
          <img src={branch1_home} alt="img" />
          <div className="textHeading">
            <h1>
              "Welcome to Saandipini Hi-Tech School, where learning begins with
              endless possibilities."
            </h1>
            <h1>Get started with us today.</h1>
            <button className="btn_contact">Contact Us</button>
          </div>
        </div>

        <div className="welcome_div">
          <p>Welcome to Sandeepani Group of Institutions</p>
          <p>Where Education meets Innovation!</p>
        </div>

        <div className="box1">
          <div className="rec1" />
          <div>
            <div className="home-title1">About Saandipini Hi-Tech school</div>
          </div>
        </div>
        <div className="home-title2">
          "Welcome to Sandipeni Hi-Tech School, Bangalore. A pioneering
          institution where cutting-edge technology meets holistic education.
          Our school is dedicated to fostering academic excellence, innovative
          thinking, and personal growth in a dynamic and supportive environment.
          With state-of-the-art facilities and a dedicated team of educators, we
          strive to prepare our students for a future where they can excel both
          academically and personally."
        </div>

        {/* best school in banglore */}
        <div className="best-school">
          <div className="br1-img">
            <img src={br1_img} alt="school_images" />

            <div className="home-title1">Best schools in Bangalore</div>

            <div className="flex-container">
              <div className="iframe-container">
                <iframe
                  width="600" 
                  height="315"
                  src="https://www.youtube.com/embed/kmOLOSS_Lmk?si=6Dj1Q8Eg2yLzKjMi"
                  title="Home-video"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  referrerpolicy="strict-origin-when-cross-origin"
                  allowfullscreen
                ></iframe>
              </div>
              <div className="description-container">
                <div className="home-title1">Virtual School Tour</div>
                <p>
                  Our institution is designed to offer a modern learning
                  experience, integrating the latest technology with a
                  comprehensive curriculum that emphasizes both academic rigor
                  and personal development.
                </p>
              </div>
            </div>
          </div>
          <div className="quickLinkContainer">
            <h1>Quick Links</h1>

            <div className="gridContainer">
              <div className="gridItem">
                <img src={admission} alt="Admission Icon" />
                <h2>ADMISSION</h2>
              </div>

              <div className="gridItem">
                <img src={campus} alt="campus" />
                <h2>OUR CAMPUS</h2>
              </div>

              <div className="gridItem">
                <img src={gallery} alt="Gallery Icon" />
                <h2>GALLERY</h2>
              </div>

              <div className="gridItem">
                <img src={facility} alt="Facilities Icon" />
                <h2>FACILITIES</h2>
              </div>

              <div className="gridItem">
                <img src={location} alt="Location Icon" />
                <h2>LOCATION</h2>
              </div>

              <div className="gridItem">
                <img src={transport} alt="Transportation Icon" />
                <h2>TRANSPORTATION</h2>
              </div>
            </div>
          </div>

          <div className="cards-container">
            <div className="card">
              <div className="card-header">Indoor Activities</div>
              <ul>
                <li>Table Tennis</li>
                <li>Play Area</li>
                <li>Board Game</li>
                <li>Chess</li>
                <li>Carrom</li>
              </ul>
            </div>
            <div className="card">
              <div className="card-header">Outdoor Activities</div>
              <ul>
                <li>Basket Ball</li>
                <li>Shuttle Badminton</li>
                <li>Football</li>
                <li>Volley Ball</li>
                <li>Kabaddi</li>
                <li>Trow Ball</li>
              </ul>
            </div>
            <div className="card">
              <div className="card-header">Co curricular Activities</div>
              <ul>
                <li>Yoga & Meditation</li>
                <li>Music</li>
                <li>Dance</li>
                <li>Art & Craft</li>
                <li>Karate</li>
              </ul>
            </div>
          </div>
        </div>

        {/* Princnipal Desk */}
        <div className="box1">
          <div className="rec1" />
          <div>
            <div className="home-title2">Principal desk</div>
          </div>
        </div>
        <div className="principal-container">
          <div className="gridDesk">
            <div className="img2">
              <img src={image12} alt="Principal_Image" />
            </div>
            <div className="home-title2">
              <p>
                "As the principal of our school, I firmly believe that fostering
                a positive and inclusive school culture is fundamental to our
                success. We prioritize creating a safe and welcoming environment
                where every student feels valued and heard. This commitment is
                reflected in our diverse programs, such as our peer mentorship
                initiative, which pairs older students with younger ones to
                build relationships and support.
              </p>
            </div>
          </div>
          <div className="home-title2">
            This not only strengthens our ties with the community but also
            enriches our students' learning experiences. By encouraging
            collaboration and open communication, we create a vibrant school
            community where everyone can thrive."
          </div>
        </div>

        {/* Top performers */}
        <div className="box1">
          <div className="rec1" />
          <div>
            <div className="home-title1">Top performers</div>
          </div>
        </div>
        <div className="flexTop">
          <div className="top1">
            <img src={top1} alt="Top-performer1" />
            <div className="h3Top">
              Sudharshan Adiga <br /> Percentage: 90%
            </div>
          </div>
          <div className="top1">
            <img src={top2} alt="Top-performer2" />
            <div className="h3Top">
              Charmi <br /> Percentage:89%
            </div>
          </div>
          <div className="top1">
            <img src={top3} alt="Top-performer3" />
            <div className="h3Top">
              Ajay G <br /> Percentage: 80%
            </div>
          </div>
          <div className="top1">
            <img src={top4} alt="Top-performer4" />
            <div className="h3Top">
              Krupa <br />
              Percentage:80%
            </div>
          </div>
        </div>

        {/* COnclusion */}
        <div className="home-title2">
          Considering{" "}
          <span className="home-title1">Saandepani Hi-Tech School?</span>
        </div>
        <div className="containerConclu">
          <div className="conclusion-content">
            <p>
              Saandeepini Hi-Tech School in Bangalore focuses on holistic
              education, blending technology with traditional learning. The
              school emphasizes innovative teaching methods, fostering
              creativity and critical thinking among students. With modern
              facilities and a commitment to academic excellence, it aims to
              prepare students for future challenges.
            </p>
          </div>
          <div className="right-section">
            <div className="gridConclu">
              <div className="grid-itemConclu">
                <span className="info">400+</span>
                <span>Students</span>
              </div>
              <div className="grid-itemConclu">
                <span className="info">18</span>
                <span>Dedicated Staff</span>
              </div>
              <div className="grid-itemConclu">
                <span className="info">4</span>
                <span>School Bus</span>
              </div>
              <div className="grid-itemConclu">
                <span className="info">7</span>
                <span>Non teaching Staff</span>
              </div>
            </div>
          </div>
        </div>

        <div className="redBox"></div>
      </div>
      <Footer />
    </div>
  );
};

export default Home;
