import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";


const Success = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const timer = setTimeout(() => {
      navigate("/sandeepanihitechschool"); 
    }, 4000);

    return () => clearTimeout(timer); // Cleanup timer
  }, [navigate]);

  return (
    <div className="success-container">
      <div className="success-message">
        <div className="success-icon">✔️</div>
        <h1 className="success-title">Payment Successful</h1>
        <div className="success-text">You will be redirected to the home page shortly...</div>
      </div>
    </div>
  );
};

export default Success;
