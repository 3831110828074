import React, { useState } from "react";
import Header from "./Header";
import Footer from "./Footer";
import { useNavigate } from "react-router-dom";
import secretaryImg from "../resources/home/secretary.jpg";
import UpArrow from "../utilities/UpArrow";

const Home = ({ homeImages }) => {
  const navigate = useNavigate();
  const navigateTo = (path) => {
    navigate(path);
  };

  const [isExpanded, setIsExpanded] = useState(false);
  const toggleReadMore = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div>
      <Header />
      <div className="home-container">
        <div className="home-title1">
          Welcome to Sandeepani Group of Institutions
        </div>
        <div className="home-title2">Where Education meets Innovation!</div>
        <div className="home-school-img">
          <img
            // src={image1}
            src={homeImages[0]}
            alt="img1"
            // style={{ width: "1080px", height: "500px" }}
          />
        </div>
        <div className="home-title2">
          <div className="underline-component">Explore Our Branches</div>
          <br />
        </div>

        <div className="branches">
          <div className="branch branch1">
            <div className="box1">
              <div className="rec1" />
              <div className="home-title3">
                Sandipini Hi-Tech School, Bangalore
              </div>
            </div>
            <div
              className="image-container"
              onClick={() => navigateTo(`/sandeepanihitechschool`)}
            >
              <img src={homeImages[1]} alt="img2" />
              <div className="overlay-text">
                Sandeepani Hi-Tech School
                <div className="website-button">View Website</div>
              </div>
            </div>
          </div>

          <div className="branch branch2">
            <div className="box1">
              <div className="rec1" />
              <div>
                <div className="home-title3">
                  Sandeepani National Public School, Ajjampura
                </div>
              </div>
            </div>
            <div
              className="image-container"
              onClick={() => navigateTo(`/sandeepaninationalpublicschool`)}
            >
              <img src={homeImages[2]} alt="img3" />
              <div className="overlay-text">
                Sandeepani National Public School
                <div className="website-button">View Website</div>
              </div>
            </div>
          </div>

          <div className="branch branch3">
            <div className="box1">
              <div className="rec1" />
              <div>
                <div className="home-title3">
                  Sandeepani Indian International Public School, Hosadurga
                </div>
              </div>
            </div>
            <div
              className="image-container"
              onClick={() => navigateTo(`/sandeepanihitechschool`)}
            >
              <img src={homeImages[3]} alt="img4" />
              <div className="overlay-text">
                Sandeepani Indian International Public School
                <div className="website-button">View Website</div>
              </div>
            </div>
          </div>

          <div className="branch branch4">
            <div className="box1">
              <div className="rec1" />
              <div>
                <div className="home-title3">
                  Sandeepani International Residential School, Talikatte
                </div>
              </div>
            </div>
            <div
              className="image-container"
              onClick={() => navigateTo(`/sandeepanihitechschool`)}
            >
              <img src={homeImages[4]} alt="img5" />
              <div className="overlay-text">
                Sandeepani International Residential School
                <div className="website-button">View Website</div>
              </div>
            </div>
          </div>

          <div className="branch branch5">
            <div className="box1">
              <div className="rec1" />
              <div>
                <div className="home-title3">
                  Sandeepani International School, Holalkere
                </div>
              </div>
            </div>
            <div
              className="image-container"
              onClick={() => navigateTo(`/sandeepanihitechschool`)}
            >
              <img src={homeImages[5]} alt="img6" />
              <div className="overlay-text">
                Sandeepani International School
                <div className="website-button">View Website</div>
              </div>
            </div>
          </div>
        </div>

        <div className="box1">
          <div className="rec1" />
          <div className="home-title3">Why Sandeepani</div>
        </div>
        <div className="home-info">
          <div className="home-title1">Comprehensive Curriculum</div>
          <div className="text-container1">
            <img className="img" src={homeImages[6]} alt="Image7" />
            <div className="home-title3">
              Our curriculum is carefully designed to provide a well-rounded
              education and foster a love for learning. From core subjects to
              extracurricular activities, we offer a wide range of programs that
              cater to student's diverse interests and abilities.
            </div>
          </div>
          <br />
          <div>
            <div className="home-title1">Innovative Teaching Methods</div>
            <div className="text-container1">
              <div className="home-title3">
                We believe in leveraging technology to enhance the learning
                experience. With state-of-the-art facilities, smart classrooms,
                and interactive learning materials, we create an engaging
                environment that encourages active participation and critical
                thinking.
              </div>
              <img className="img" src={homeImages[7]} alt="img8" />
            </div>
          </div>
          <br />
          <div className="home-title1">Experienced and Caring Faculty</div>
          <div className="text-container1">
            <img className="img" src={homeImages[0]} alt="img9" />

            <div className="home-title3">
              Our team of dedicated educators is passionate about teaching and
              genuinely cares about the success of each student. They foster a
              nurturing atmosphere where students feel supported, motivated, and
              inspired to reach their full potential.
            </div>
          </div>
          <br />
          <div className="home-title1">Language Development</div>
          <div className="text-container1">
            <div className="home-title3">
              Our language development program focuses on honing communication
              skills, enhancing vocabulary, and fostering a love for
              languages,both local and international.
            </div>
            <img className="img" src={homeImages[8]} alt="img10" />
          </div>
        </div>

        <div className="box1">
            <div className="rec1"></div>
            <div className="home-title2">Mission & Vision</div>
          </div>
        <div>
          <div className="mission-vision-div">
            <br />
            <div className="mission">
              <div className="h5">Mission</div>
              <p className="par1">
                Our goal is to build skills that set children up for success in
                the Sandeepani Group of Schools and beyond.
                <br></br>
                We believe in providing a holistic that prepares students for
                the challenges and opportunities of a rapidly changing world{" "}
              </p>
            </div>
            <div className="vision">
              <div className="vis">Vision</div>
              <p className="par3">
                To educate all students to the highest levels of academic
                achievement, to enable them to reach and expand their potential,
                and to prepare them to become productive, responsible, ethical,
                creative, and compassionate members of society.
              </p>
            </div>
          </div>
        </div>


          <div className="box1">
            <div className="rec1"></div>
            <div className="home-title2">Chairman's Desk</div>
          </div>

          <div className="sec1-container">
            <div className="img2">
              <img src={homeImages[9]} alt="img11" />
            </div>
            <div className="home-title1">Chairman</div>
            <div className="home-title2">Lokesh Talikatte</div>
            <div className="description">
              <div className={`hidden-content ${isExpanded ? "show" : ""}`}>
                Students today have an unquenchable thirst to seek knowledge
                that cannot be satiated by traditional methods of imparting
                education like rote learning and passive listening. With the
                paradigm shift in the field of education, children at Sandeepani
                group of institutions will be provided with opportunities and
                situations that facilitate them to exhibit their two-hidden
                potential to the fullest.
              </div>
              <div className={`hidden-content ${isExpanded ? "show" : ""}`}>
                We believe that a good education is one that shapes the growth
                of an individual’s creativity, knowledge, and character. Our
                accredited curriculum is designed to recognize individual
                differences and foster the development of each student’s
                intellect and personality. We strive to meet the needs of the
                whole child and encourage our students to explore, inquire, and
                to think critically.
                <br />
              </div>
            </div>
            <button class="read-more-button" onClick={toggleReadMore}>
              {" "}
              {isExpanded ? "Read Less" : "Read More"}
            </button>
          </div>
          <div className="box1">
            <div className="rec1"></div>
            <div className="home-title2">School secretary</div>
          </div>

          <div className="sec1-container">
            <div className="img2">
              <img src={secretaryImg} alt="img13" />
            </div>
            <div className="home-title1">Secretary</div>
            <div className="home-title2">Chandrakala</div>
          </div>

          <div className="box1">
            <div className="rec1"></div>
            <div className="home-title2">Director’s Desk</div>
          </div>
          <div>
          <div className="sec1-container">
            <div className="img2">
              <img src={homeImages[10]} alt="img12" />
            </div>
            <div className="home-title1">Director</div>
            <div className="home-title2">Roopa</div>
            <div className="description">
              <div className="hidden-content">
                As the Director of Sandeepani Group of Schools, it is with
                immense pleasure and pride that I welcome you to our vibrant
                educational community. Our institution stands as a beacon of
                excellence, committed to nurturing young minds and fostering a
                lifelong love for learning.
              </div>

              <div className={`hidden-content ${isExpanded ? "show" : ""}`}>
                At Sandeepani, we believe in holistic education that goes beyond
                textbooks and classrooms. Our mission is to cultivate an
                environment where students can explore their passions, develop
                critical thinking skills, and grow into responsible global
                citizens. We are dedicated to providing a balanced curriculum
                that integrates academic rigor with co-curricular activities,
                ensuring our students emerge well-rounded and prepared for the
                challenges of the future.
                <br />
              </div>
              <div className={`hidden-content ${isExpanded ? "show" : ""}`}>
                Our team of dedicated educators is at the heart of this mission.
                Their unwavering commitment to student success, combined with
                innovative teaching methodologies, creates a dynamic and
                engaging learning experience. We continuously strive to stay at
                the forefront of educational advancements, incorporating the
                latest technologies and pedagogical practices to enhance our
                students' learning journey. Parents play a crucial role in the
                educational process, and we value your partnership and support.
                Together, we can create a nurturing and motivating atmosphere
                that encourages our children to aim high and achieve their
                dreams. We encourage open communication and collaboration, as
                your insights and feedback are invaluable to us.
              </div>
              <div className={`hidden-content ${isExpanded ? "show" : ""}`}>
                To our students, I say this: Dream big, work hard, and stay
                curious. Embrace every opportunity to learn and grow, both
                inside and outside the classroom. Remember that education is not
                just about acquiring knowledge but also about building character
                and developing a sense of responsibility towards society.
              </div>
              <div className={`hidden-content ${isExpanded ? "show" : ""}`}>
                As we move forward, we remain committed to our vision of
                excellence and our mission to educate, empower, and inspire. I
                look forward to another successful year filled with
                achievements, discoveries, and joyous moments.
              </div>
              <div className={`hidden-content ${isExpanded ? "show" : ""}`}>
                Thank you for being a part of the Sandeepani family. Together,
                let us continue to build a brighter future.
              </div>
            </div>
            <button class="read-more-button" onClick={toggleReadMore}>
              {" "}
              {isExpanded ? "Read Less" : "Read More"}
            </button>
          </div>
          </div>
          
        
        <UpArrow />
      </div>

      <Footer />
    </div>
  );
};

export default Home;


