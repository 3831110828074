import React, { useState } from "react";

import axios from "axios";
import Header from "./Header";
import Footer from "./Footer";
import { useAuth } from "../context/AuthContext";
import toast from "react-hot-toast";
import Razorpay from "./RazorPay";
const GenerateAmount = () => {
  const { isAuthenticated } = useAuth();
  
  const [admissionNo, setAdmissionNo] = useState("");
  const [studentDetails, setStudentDetails] = useState(null);
  const [amount, setAmount] = useState(1000);

  const pay = Razorpay();

  const handlePayment = () => {
    pay(amount);
  };
  const handleGenerateAmount = async () => {
    try {
      const response = await axios.post(
        "https://sandeepanischools.in/b1/fee/generate-amount",
        {
          admissionNo,
        }
      );
      setStudentDetails(response.data);
      toast.success("Fetching Payment Details");
    } catch (error) {
      toast.error(error.response?.data?.error)
    }
  };

  return (
    <div>
      <Header />
      {isAuthenticated ? (
        <div className="container-generate">
          <div className="home-title1">Generate Due Amount</div>
          <input
            type="text-generate"
            placeholder="Admission Number"
            value={admissionNo}
            onChange={(e) => setAdmissionNo(e.target.value)}
          />
          <button className="payment-button" onClick={handleGenerateAmount}>
            Generate Amount
          </button>
          {studentDetails && (
            <div className="student-details-generate">
              <h2>Student Details:</h2>
              <div className="detail-row">
                <span className="label-generate">Admission No:</span>
                <span className="value-generate">
                  {studentDetails.admissionNo}
                </span>
              </div>
              <div className="detail-row">
                <span className="label-generate">Name:</span>
                <span className="value-generate">{studentDetails.name}</span>
              </div>
              <div className="detail-row">
                <span className="label-generate">Class:</span>
                <span className="value-generate">{studentDetails.class}</span>
              </div>
              <div className="detail-row">
                <span className="label-generate">Total Amount:</span>
                <span className="value-generate">
                  {studentDetails.totalAmount}
                </span>
              </div>
              <div className="detail-row">
                <span className="label-generate">Total Payments:</span>
                <span className="value-generate">
                  {studentDetails.totalPayments}
                </span>
              </div>
              <div className="detail-row">
                <span className="home-title2">Due Amount:</span>
                <span className="home-title1">{studentDetails.dueAmount}</span>
              </div>

              <h3>Payment History:</h3>
              {studentDetails.paymentHistory1 &&
              studentDetails.paymentHistory1.length > 0 ? (
                <ul>
                  {studentDetails.paymentHistory1.map((payment, index) => (
                    <li key={index}>
                      <strong>Payment ID:</strong> {payment.paymentId} <br />
                      <strong>Amount:</strong> {payment.amount}
                    </li>
                  ))}
                </ul>
              ) : (
                <p>No payments found.</p>
              )}
              <br />

              <div className="final-payment">
                <div className="home-title3">Pay now :</div>
                <input
                  type="number"
                  placeholder="paying amount"
                  
                  onChange={(e) => setAmount(e.target.value)}
                />
                <button className="payment-button" onClick={handlePayment}>
                  Pay Now
                </button>
              </div>
            </div>
          )} 
        </div>
      ) : (
        <div className="container-generate">
          <div className="home-title1">
            Please login to proceed for payments
          </div>
        </div>
      )}

      <Footer />
    </div>
  );
};

export default GenerateAmount;
