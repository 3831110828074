import { useNavigate } from "react-router-dom";

const Footer = () => {
  const navigate = useNavigate();
  const navigateTo = (path) => {
    navigate(path);
  };
  return (
    <footer className="b1-footer">
      <div className="b1-footer-content">
        <div className="b1-footer-left">
          <div className="b1-ft-title">Sandeepani Hi-Tech School</div>
          <div className="b1-caption">
            We enable personalised growth for every child
          </div>
          <div className="b1-caption">
            impacting thousands of students across schools.
          </div>

          <div
            className="b1-ft-title"
            onClick={() => navigateTo("/sandeepanihitechschool/about")}
          >
            About Us
          </div>

          {/* <div className="b1-ft-title">Quick Links</div>
          <div>here goes links</div> */}
        </div>
        <div className="b1-footer-right">
          <div className="b1-ft-title">Contact Us</div>
          <div>Phone: +91 9606447701 , 9036067702</div>
          <div>Email: saandipinischool@gmail.com</div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
