import Header1 from "../components/Header";
import Footer from "../components/Footer";
// const facultiesData = {
// //   "0": ["Suma M", "MA, B.Ed, M.Ed", "11 years", "https://i.ibb.co/3m2bkX6/Suma-M.png"],
// //   "1": { "0": "Kavitha", "1": "M.com, B.Ed ", "2": "15 years", "3": "https://i.ibb.co/gM1VztD/Kavitha.png" },
// //   "2": { "0": "Veena S Kakaade", "1": "MCA, Diploma in E & C ", "2": "7 years", "3": "https://i.ibb.co/5xjdbnh/VEENA-S.png" },
// //   "3": { "0": "Shwetha H ", "1": "B.com, NTT ", "2": "6 years", "3": "https://i.ibb.co/zhY4Wxw/SWHETHA-H.png" },
// //   "4": { "0": "Anitha Praveen", "1": "BA, NTT ", "2": "15 years", "3": "https://i.ibb.co/C6crYRg/ANITHA-PRAVEEN.png" },
// //   "5": { "0": "Shruthi B", "1": " MA, BEd ", "2": "7 years", "3": "https://i.ibb.co/X8ytMjT/SHRUTHI-B.png" },
// //   "6": { "0": "Rekha V Shashikar ", "1": "BA, B.Ed", "2": "5 years", "3": "https://i.ibb.co/MD1VN1q/REKHA-V.png" },
// //   "7": { "0": "Sumana Mukerjee ", "1": "BA, B.Ed", "2": "10 Years", "3": "https://i.ibb.co/pf4gyJw/SUMANA-MUKARJEE.png" },
// //   "8": { "0": "Ramamani K ", "1": "B.Ed, M.Sc ", "2": "4 Years", "3": "https://i.ibb.co/3kRYMG5/RAMAMANI-K.png" },
// //   "9": { "0": "Priya Anoop", "1": "MA, B.Ed ", "2": "18 Years", "3": "https://i.ibb.co/Z2vHqmL/POOJA-ANUP.png" },
// //   "10": { "0": "Celine Gijo", "1": "Diploma in Counselling", "2": "14 Years", "3": "https://i.ibb.co/k8JVKwF/Celine-Gijo.png" },
// //   "11": { "0": "Padmavathi H", "1": "MA, B.Ed ", "2": "5 Years", "3": "https://i.ibb.co/pzMMwK1/Padmavathi-H.png" },
// //   "12": { "0": "Pavithra R", "1": "B.com, NTT ", "2": "1 Year", "3": "https://i.ibb.co/D5ZTvxR/Pavithra-R.png" },
// //   "13": { "0": "Kavitha BL", "1": "B.L, NTT ", "2": "9 Years", "3": "https://i.ibb.co/85wwYsR/Kavitha-BL.png" },
// //   "14": { "0": "Krishnadas R", "1": "M.Ped, NIS", "2": "1 year", "3": "https://i.ibb.co/ygNCGKs/Krishnadas-R.png" }
// // };

const Faculties = ({facultiesData}) => {
        
  // Transform the `facultiesData` into an array of objects
  const transformedData = Object.entries(facultiesData).map(([key, value]) => {
    // Ensure the data is normalized for both array and object formats
    const [name, qualification, experience, img] = Array.isArray(value) ? value : Object.values(value);
    return {
      id: key,
      name,
      qualification,
      experience,
      img,
    };
  });
  console.log(transformedData)

  return (
    <div>
      <Header1 />
      <div className="faculties-container-main">
        <div className="faculties-container-card">
          {transformedData.map((faculty) => (
            <div className="faculty-card" key={faculty.id}>
              <img src={faculty.img} alt={faculty.name} className="faculty-image" />
              <div className="faculty-info">
                <h3 className="faculty-name">{faculty.name}</h3>
                <h6 className="faculty-qualification">{faculty.qualification}</h6>
                <h6 className="faculty-experience">{faculty.experience}</h6>
              </div>
            </div>
          ))}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Faculties;
