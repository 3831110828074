import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { Toaster } from "react-hot-toast";
import Home from "./components/Home";
import Contact from "./components/Contact";
import Gallery from "./components/Gallery";
import About from "./components/About";
import News from "./components/News";
import toast from "react-hot-toast";
import Home2 from "./branches/branch2/components/Home";
 

import { AuthProvider } from "./branches/branch1/context/AuthContext";
import Generate1 from "./branches/branch1/components/generate";
import Home1 from "./branches/branch1/pages/Home";
import Gallery1 from "./branches/branch1/pages/Gallery";
import Contact1 from "./branches/branch1/pages/Contact";
import News1 from "./branches/branch1/pages/News";
import About1 from "./branches/branch1/pages/About";
import Faculties1 from "./branches/branch1/pages/Faculties";
import Admissions1 from "./branches/branch1/pages/Admissions";
import Login1 from "./branches/branch1/pages/Login";
import Success1 from "./branches/branch1/components/SuccessedPayment";
import Failure1 from "./branches/branch1/components/failedPayment";
import Application1 from "./branches/branch1/components/Application";

function App() {
  const [images, setImages] = useState({ homeImages: [], galleryImages: [] });
  const [images1, setImages1] = useState({ faculties: {}, galleryImgUrls: [] });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchImages = async () => {
      try {
        const response = await axios.get(
          "https://sandeepanischools.in/mainPage/images"
        );
        setImages(response.data);
        setLoading(false);
      } catch (err) {
        toast.error("Failed to load images. Please try again later.");
        console.log(err);
        setLoading(true);
      }
    };
    fetchImages();

     const fetchImages1 = async () => {
          try {
            const response = await axios.get(
              "https://sandeepanischools.in/b1/images"
            );
            setImages1(response.data);
          } catch (err) {
            console.error("Error fetching images:", err);
          }
        };
        fetchImages1();
    setTimeout(() => setLoading(false), 5000);
  }, []);

  if (loading) {
    return (
      <div className="loading-container">
        <div className="spinner"></div>
      </div>
    );
  }

  return (
    <>
    <AuthProvider>
      <Toaster position="top-right"/>
      <Router>
        <Routes>
          <Route path="/" element={<Home homeImages={images.homeImages} />} />
          <Route path="/contact" element={<Contact />} />
          <Route
            path="/gallery"
            element={<Gallery galleryImages={images.galleryImages} />}
          />
          <Route path="/about" element={<About />} />
          <Route
            path="/news"
            element={<News notificationImages={images.notificationImages} />}
          />

          {/* branch1 */}
          

        
          <Route
            path="/sandeepanihitechschool/generate"
            element={<Generate1 />}
          />
          <Route path="/sandeepanihitechschool/failure" element={<Failure1 />} />
          <Route path="/sandeepanihitechschool/success" element={<Success1 />} />
          <Route path="/sandeepanihitechschool" element={<Home1 />} />
          <Route
            path="/sandeepanihitechschool/gallery"
            element={<Gallery1 galleryImgUrls={images1.galleryImgUrls} />}
          />
          <Route path="/sandeepanihitechschool/news" element={<News1 />} />
          <Route path="/sandeepanihitechschool/contact" element={<Contact1 />} />
          <Route path="/sandeepanihitechschool/about" element={<About1 />} />
          <Route
            path="/sandeepanihitechschool/faculties"
            element={<Faculties1 facultiesData={images1.faculties} />}
          />
          <Route
            path="/sandeepanihitechschool/admissions"
            element={<Admissions1 />}
          />
          <Route path="/sandeepanihitechschool/login" element={<Login1 />} />

          <Route
            path="/sandeepanihitechschool/application"
            element={<Application1 />}
          />
          
          {/* branch 2 */}
          <Route path="/sandeepaninationalpublicschool" element={<Home2 />} />
        </Routes>
      </Router>
      </AuthProvider>
     
  
    </>
  );
}

export default App;
