import Header from "./Header";
import Footer from "./Footer";
import { useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

const Gallery = ({ galleryImages }) => {
  const [loadedImages, setLoadedImages] = useState([]); // Keep track of fully loaded images

  const handleImageLoad = (index) => {
    setLoadedImages((prevLoaded) => [...prevLoaded, index]);
  };

  const [selectedImage, setSelectedImage] = useState(null);

  const openModal = (src) => {
    setSelectedImage(src);
  };

  const closeModal = () => {
    setSelectedImage(null);
  };

  return (
    <div>
      <Header />
      <div className="gallery-container">
        <div className="grid-container">
          {galleryImages.map((image, index) => (
            <LazyLoadImage
              key={index}
              className={`grid-item ${loadedImages.includes(index) ? "visible" : "hidden"}`}
              src={image}
              alt="Gallery Image"
              effect="blur" // Adds a blur effect while loading
              onLoad={() => handleImageLoad(index)} // Mark image as loaded
              placeholderSrc="https://via.placeholder.com/300" // Placeholder image
              onClick={() => openModal(image)}
            />
          ))}
        </div>

        {/* Modal for larger image view */}
        {selectedImage && (
          <div className="modal" onClick={closeModal}>
            <img className="modal-image" src={selectedImage} alt="Selected" />
          </div>
        )}
      </div>
      <Footer />
    </div>
  );
};

export default Gallery;
