import Header from "./Header";
import Footer from "./Footer";
// import "../stylesheets/About.css";
import UpArrow from "../utilities/UpArrow";

const About = () => {
  return (
    <div>
      <Header />
      <div className="about-container">
        <div className="abt-title1">Welcome to Sandeepani Group of Schools</div>
        <div className="abt-title2">
          At Saandeepani Group of Schools, we believe in nurturing young minds
          and fostering holistic development through education. Established with
          a vision to provide excellence in education, we have been a
          cornerstone in the educational landscape for over years. Our
          commitment to academic rigor, character building, and innovation sets
          us apart as a leader in the field of education.
        </div>

      
        <div>
          <div className="abt-rectangles">
            <div className="rectangle1">
              <div className="head1">Our Mission</div>
              <div className="section1">
                Our mission is to empower every student to achieve their full
                potential academically, socially, and emotionally. We aim to
                cultivate a love for learning, critical thinking skills, and a
                sense of responsibility towards society.
              </div>
            </div>
            <div className="rectangle2">
              <div className="head2">Academic Excellence</div>
              <div className="section2">
                At Sandeepani Group of Schools, academic excellence is at the
                heart of everything we do. Our curriculum is designed to be
                rigorous yet flexible, ensuring that each student receives a
                well-rounded education. We emphasize not only academic knowledge
                but also practical skills and experiential learning
                opportunities
              </div>
            </div>
            <div className="rectangle1">
              <div className="head1">Holistic Development</div>
              <div className="section1">
                We recognize the importance of holistic development and offer a
                wide range of extracurricular activities including sports, arts,
                music, and community service programs. These activities are
                designed to encourage creativity, teamwork, leadership, and
                personal growth.
              </div>
            </div>
            <div className="rectangle2">
              <div className="head2">Infrastructure and Facilities</div>
              <div className="section2">
                Our state-of-the-art infrastructure supports a conducive
                learning environment. Modern classrooms, well-equipped
                laboratories, libraries stocked with a diverse range of books,
                and recreational areas contribute to a stimulating educational
                experience.
              </div>
            </div>
            <div className="rectangle1">
              <div className="head1">Qualified Faculty</div>
              <div className="section1">
                Our dedicated team of educators are highly qualified,
                passionate, and committed to the success of every student. They
                provide personalized attention, mentorship, and guidance,
                ensuring that each student receives the support they need to
                thrive.
              </div>
            </div>
            <div className="rectangle2">
              <div className="head2">Parental Engagement</div>
              <div className="section2">
                We believe in the importance of a strong partnership between the
                school and parents. Regular communication, parent-teacher
                meetings, and workshops ensure that parents are actively
                involved in their child's educational journey.
              </div>
            </div>
            <div className="rectangle1">
              <div className="head1">Global Perspective</div>
              <div className="section1">
                In an increasingly interconnected world, we prepare our students
                to be global citizens. Through international exchange programs,
                cultural exchanges, and exposure to global issues, we broaden
                their horizons and instill a sense of empathy and understanding.
              </div>
            </div>
            <div className="rectangle2">
              <div className="head2">Community Outreach</div>
              <div className="section2">
                Sandeepani Group of Schools is committed to giving back to the
                community. Our students actively participate in various outreach
                programs and initiatives, promoting social responsibility and
                making a positive impact on society.
              </div>
            </div>
          </div>
        </div>
      </div>
      <UpArrow />
      <Footer />
    </div>
  );
};

export default About;
