import Header1 from "../components/Header";
import Footer from "../components/Footer";
import { useState } from "react";
// import "../stylesheets/News.css";

const videos = [
  { id: "Ylnd_wxdiH8?si=Lwa8Re2wGnflKVlt", title: "Blue Day Celebrations" },
  { id: "6CIdJHetIqo?si=L7feziLFqBlgZPgg", title: "Drug Awareness Day" },
  { id: "yqWedguj5vM?si=Y-TgwRXkUonk-GuS", title: "Family Fun Day" },
];
 const channelUrl="https://www.youtube.com/@Saandipini_Hi_Tech_School/featured"
const News = () => {
  const [activeVideo, setActiveVideo] = useState(null);

  const handlePlay = (videoId) => {
    setActiveVideo(videoId);
  };
  return (
    <div>
      <Header1 />
      <div className="video-grid-container">
      <div className="video-grid">
        {videos.map((video, index) => (
          <div
            className="video-card"
            key={index}
            onClick={() => handlePlay(video.id)}
          >
            <iframe
              width="100%"
              height="200"
              src={`https://www.youtube.com/embed/${video.id}?autoplay=${activeVideo === video.id ? 1 : 0}`}
              title={video.title}
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
            <p className="video-title">{video.title}</p>
          </div>
        ))}
      </div>
      <div className="subscribe-section">
        <a href={channelUrl} target="_blank" rel="noopener noreferrer">
          <button className="subscribe-button">Visit YouTube Channel</button>
        </a>
      </div>
    </div>
      <Footer />
    </div>
  );
};

export default News;
