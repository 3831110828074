import React, { useState, useRef } from 'react';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import Header1 from './Header';
import Footer1 from './Footer';

const Application = () => {
  const formRef = useRef(null);
  const { register, handleSubmit, formState: { errors }, watch, reset,setValue } = useForm();
  const photoLabels = ["Student Photo", "Father Photo", "Mother Photo", "Aadhar Card", "Voter ID Card"];
  const [rows, setRows] = useState(Array(6).fill({}));
  const [sameAsResidential, setSameAsResidential] = useState(false);

  // const [] = useState({
  //   studentPhoto: null,
  //   fatherPhoto: null,
  //   motherPhoto: null,
  //   aadharCard: null,
  //   voterIdCard: null,
  // });

  

  const handleAddRow = () => {
    setRows([...rows, {}]); // Add a new row
  };

  
  const handleRemoveRow = () => {
    if (rows.length > 1) {
      setRows(rows.slice(0, -1)); // Remove the last row
    }
  };

  const onSubmit = async (data) => {
    try {
      const response = await axios.post(
        'https://script.google.com/macros/s/AKfycbwWqxaIHH_NJho9YWvQ12_8L--lLQA1VLuqcBDhv6AWaqYb0hItof06HqY6D5xv8qzX/exec', 
        data
      );
      if (response.data.status === 'success') {
        alert('Data added to Google Sheet successfully!');
      }
    } catch (error) {
      console.error('Error adding data to Google Sheet:', error);
      alert('Failed to add data to Google Sheet.');
    }
  };

  const handleReset = () => {
    reset();
  };

  const handleSameAsResidentialChange = () => {
    setSameAsResidential(!sameAsResidential);

    if (!sameAsResidential) {
      setValue("permanentHouseNo", watch("houseNo"));
      setValue("permanentArea", watch("area"));
      setValue("permanentStreet", watch("street"));
      setValue("permanentCity", watch("city"));
      setValue("permanentState", watch("state"));
      setValue("permanentPinCode", watch("pinCode"));
    } else {
      setValue("permanentHouseNo", "");
      setValue("permanentArea", "");
      setValue("permanentStreet", "");
      setValue("permanentCity", "");
      setValue("permanentState", "");
      setValue("permanentPinCode", "");
    }
  };


  const InputField = ({
    placeholder,
    name,
    pattern,
    type = 'text',
    required = true,
    errorMessage,
    className = '' 
  }) => (
    <div className="form-group">
      <input
        type={type}
        className={`input ${className}`} 
        placeholder={placeholder}
        {...register(name, { required, pattern })}
      />
      {errors[name] && <span>{errorMessage || 'This field is required'}</span>}
    </div>
  );
  

  const tableStyles = {
    cell: {
      border: '1px solid #FF0000',
      padding: '8px',
      textAlign: 'center',
    },
  };

  const inputStyles = {
    width: '100%',
    padding: '5px',
    margin: '5px 0',
    boxSizing: 'border-box',
    border:'none'
  };

  const errorStyles = {
    color: 'red',
    fontSize: '12px',
  };

  return (
    <div>
      <Header1 />
    <div className="application">
      <div className="registration-form">
        <div className="formHeading">
          <h1>REGISTRATION FORM</h1>
        </div>
        
        <form onSubmit={handleSubmit(onSubmit)} ref={formRef}>
          <div className='container-application-no'>
          <h1 className='application-name'>Application No: </h1>
          <InputField placeholder name="applicationNo" className = "application-no"/>
          </div>
          <div className="addressSection">
          <InputField placeholder="Name of Student (In Capital Letters):" name="studentName" required errorMessage="Student Name is required" />
          <div className='container-split-desktops'>
            <select className="dateInput" {...register("gender", { required: true })}>
                  <option value="">Gender</option>
                  <option value="male">Male</option>
                  <option value="female">Female</option>
                  <option value="other">Other</option>
                </select>
                {errors.gender && <span>This field is required</span>}
            <InputField type='date' name="dateofbirth" className="dateInput" errorMessage="Date Of Birth Required"/>
          </div>
              
          <InputField placeholder="Student Aadhar Number" type='number' name="aadharNumber" pattern={/^[0-9]{12}$/} errorMessage="Invalid Aadhar Number" className='input-number'/>
          <div className='container-split-desktops'>
            <InputField placeholder="Religion:" name="religion" />
            <InputField placeholder="Caste & Category" name="casteCategory" />
          </div>
          
          <InputField placeholder="Mother Tongue:" name="motherTongue" />
          


          </div>
          <div className="addressSection">
            <h2>Residential Address</h2>
            <div className='container-split-desktops'>
              <InputField placeholder="House No.:" name="houseNo" />
              <InputField placeholder="Area:" name="area" />
            </div>
            <div className='container-split-desktops'>
              <InputField placeholder="Street:" name="street" />
              <InputField placeholder="City:" name="city" />
            </div>
            <div className='container-split-desktops'>
              <InputField placeholder="State:" name="state" />
              <InputField placeholder="Pin Code:" name="pinCode" type="number" />
            </div>
            
          </div>

          <div className="addressSection">
            <h2>Permanent Address</h2>
            <label className="same-address-css">
              <span className='span-check-box'>Same as Residential Address?</span>
              <input
                type="checkbox"
                className='checkbox-css'
                checked={sameAsResidential}
                onChange={handleSameAsResidentialChange}
              />
            </label>


            <div className='container-split-desktops'>
              <InputField placeholder="House No.:" name="permanentHouseNo" />
              <InputField placeholder="Area:" name="permanentArea" />
            </div>
            <div className='container-split-desktops'>
              <InputField placeholder="Street:" name="permanentStreet" />
              <InputField placeholder="City:" name="permanentCity" />
            </div>
            <div className='container-split-desktops'>
              <InputField placeholder="State:" name="permanentState" />
              <InputField placeholder="Pin Code:" name="permanentPinCode" type="number" />
            </div>
  
          </div>


          <div className="addressSection">
            <h2>Mobile No and Locality Code</h2>
            <div className='container-split-desktops'>
              <InputField placeholder="Phone No.:" name="phoneno" type='number' />
              <InputField placeholder="locality code." name="localitycode"/>
            </div>
            <div className='container-split-desktops'>
              <InputField placeholder="Distance from school:" name="Distancefromschool" />
              <InputField placeholder="Alternate Phone no." name="Alternate Phone no." />
            </div>
            
            
          </div>

          <div className="addressSection">
            <h2>Personal Details Of Father</h2>
            <InputField placeholder="Father Name" name="fathername" />
            <div className='container-split-desktops'>
              <InputField placeholder="Phone No." name="phoneno" type='number' />
              <InputField placeholder="Occupation" name="occupation" />
            </div>
            
          </div>

          <div className="addressSection">
            <h2>Personal Details Of Mother</h2>
            <InputField placeholder="Mother Name" name="mothename" />
            <div className='container-split-desktops'>
              <InputField placeholder="Phone No." name="phoneno" type='number' />
              <InputField placeholder="Occupation" name="occupation" />
            </div>

          </div>

          <div className="addressSection">
            <h2>Personal Details Of Guardian </h2>
            <InputField placeholder="Guardian Name" name="guardianname" />
            <div className='container-split-desktops'>
              <InputField placeholder="Phone No." name="phoneno" type='number' />
              <InputField placeholder="Occupation" name="occupation" />
            </div>
            
          </div>

          <div className="addressSection">
            <h2>Details Of Previews School</h2>
            <label className="same-address-css">
              <span className='span-check-box'>Sandeepini School Only</span>
              <input
                type="checkbox"
                className='checkbox-css'
              />
            </label>
              <InputField placeholder="Name Of Previews School" name="nameofthepreviewschool"/>
              <textarea placeholder="Address Of Previews School" name="addressofpreviewsschool" className='textarea-field'/>
              <InputField placeholder="Medium Of Instruction" name="mediumofinstruction"/>
              <InputField placeholder="Name Of The Board Of Previous School" name="nameoftheboardofpreviousschool"/>
              <div className='container-split-desktops'>
                <InputField placeholder="TC No." name="tcno"/>
                <InputField type='date' name="dateofbirth" className="dateInput" errorMessage="Date Of Birth Required"/> 
              </div>
              
              <InputField placeholder="DISE Code" name="disecode"/>
          </div>
        <div className="addressSection">
          <h2>Details of Academic Performance</h2>  
          <table style={{ borderCollapse: "collapse", width: "100%" }}>
        <thead>
          <tr>
            <th style={tableStyles.cell}>No.</th>
            <th style={tableStyles.cell}>Subject Name</th>
            <th style={tableStyles.cell}>Max Marks</th>
            <th style={tableStyles.cell}>Obtained Marks</th>
            <th style={tableStyles.cell}>Percentage</th>
          </tr>
        </thead>
        <tbody>
          {rows.map((_, index) => {
            const maxMarks = watch(`academicPerformance[${index}].maxMarks`);
            const obtainedMarks = watch(`academicPerformance[${index}].obtainedMarks`);
            const percentage = maxMarks && obtainedMarks ? ((obtainedMarks / maxMarks) * 100).toFixed(2) : '';

            return (
              <tr key={index}>
                <td style={tableStyles.cell}>
                  {index + 1} {/* Automatically generates the serial number */}
                </td>
                <td style={tableStyles.cell}>
                  <input 
                    type="text"
                    {...register(`academicPerformance[${index}].subject`, { required: false })}
                    style={inputStyles}
                  />
                  {errors.academicPerformance?.[index]?.subject && <span style={errorStyles}>Required</span>}
                </td>
                <td style={tableStyles.cell}>
                  <input
                    type="number"
                    {...register(`academicPerformance[${index}].maxMarks`, { required: false })}
                    style={inputStyles}
                  />
                  {errors.academicPerformance?.[index]?.maxMarks && <span style={errorStyles}>Required</span>}
                </td>
                <td style={tableStyles.cell}>
                  <input
                    type="number"
                    {...register(`academicPerformance[${index}].obtainedMarks`, { required: false })}
                    style={inputStyles}
                  />
                  {errors.academicPerformance?.[index]?.obtainedMarks && <span style={errorStyles}>Required</span>}
                </td>
                <td style={tableStyles.cell}>
                  <input
                    type="number"
                    value={percentage}
                    readOnly
                    style={inputStyles}
                  />
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      </div>
      <div className='btn-container'>
        {/* Add Row Button (After the last row) */}
        <button type="application-button" onClick={handleAddRow} className='btn' >
          +
        </button>

        {/* Remove Row Button (After the last row) */}
        {rows.length > 1 && (
          <button type="application-button" onClick={handleRemoveRow} className='btn btn1'>
           -
          </button>
        )}
      </div>
          <div className="photoLabels">
          <h2>Photograph</h2>
          <div className="photo-container">
          {photoLabels.map((label, idx) => (
              <div key={idx} className="form-group">
                <label>{label}:</label>
                <input
                  type="file"
                  accept="image/*"
                  className="input"
                  {...register(`image_${idx}`, { required: true })}
                />
                {errors[`image_${idx}`] && <span>This field is required</span>}
              </div>
            ))}
            </div>
          </div>

          <div className="form-actions">
            <button type="submit">Submit</button>
            <button type="application-button" onClick={handleReset}>Reset</button>
          </div>
        </form>
      </div>
    </div>
    <Footer1/>
    </div>
  );
};

export default Application;